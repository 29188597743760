import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private _auth: AuthService, private _http: HttpService) {}

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        let passed: boolean = false;

        // Check if the user is logged in if needed.
        passed = (await this._auth.isLoggedIn()) === next.data.requiresLogin;

        // Check if the user is allowed if needed.
        if (next.data.requiresAllowedCheck) {
            passed = await this._auth.isAllowed(next.data.sentAllowedLocation);
        }

        // Checking if it failed, and if it did, redirect to the failRedirect.
        if (!passed) {
            this._auth.failRedirect(next.data.failRedirectAuth, state.url);
        }

        return passed;
    }

    async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        let allowed = await this._auth.isAllowed(route.data.location);
        if (!allowed) {
            await this._http.redirectHomeMagic(true, segments.join('/'));
        }

        return allowed;
    }
}
